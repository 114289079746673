import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useActions } from "../../../../hooks/redux-hooks/useActions";
import styles from "./WalletModalStepTwo.module.scss";
import { useTypedSelector } from "./../../../../hooks/redux-hooks/useTypedSelector";
import { errorHandler } from "../../../../helpers/errorHandler";
import Loader from "./../../../../components/Loader/Loader.component";
import { PAYMENT_OPTIONS } from "../../../../helpers/paymentOptions";
import { selectWalletTopUp } from "../../../../redux/selectors/walletTopUpSelector";
import { postData } from "./../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { ReactComponent as SuccessIcon } from "../../../../assets/img/coin-icon.svg";
import { paystackConfig } from "../../../../helpers/thirdPartyConfigs";
import {
  selectUserEmail,
  selectUserId,
} from "../../../../redux/selectors/userSelector";
import { usePaystackPayment } from "react-paystack";
import { useQueryClient } from "react-query";
import useWalletAccountDetails from "../../../../hooks/custom-hooks/useWalletAccountDetails";
import PageLoader from "../../../../components/PageLoader/PageLoader.component";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface IWithdrawModalStepTwo {
  closeModal: () => void;
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
  error: string;
  success: string;
}

export const WalletModalStepTwo: React.FC<IWithdrawModalStepTwo> = ({
  closeModal,
  error,
  success,
  setError,
  setSuccess,
}) => {
  const [type, setType] = useState<
    typeof PAYMENT_OPTIONS.PAYSTACK | typeof PAYMENT_OPTIONS.BANK_TRANSFER | ""
  >("");
  const [paymentRef, setPaymentRef] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentResponse, setPaymentResponse] = useState("");
  const [displayTransferDetails, setDisplayTransferDetails] = useState(false);
  const [copied, setCopied] = useState("");
  const [loading, setLoading] = useState(false);
  const [domDetails, setDomDetails] = useState({
    accountName: "Advancly Technologies Nigeria Limited",
    bankName: "Zenith Bank Plc",
    accountNumber: 5072284033,
    beneficiaryBankSwiftCode: "ZEBINGLA",
    swiftCode: "CITIUS33",
    correspondingBank: "Citibank N.A",
  });
  const { setWalletTopUpInfo } = useActions();
  const walletTopUpInfo = useTypedSelector(selectWalletTopUp);
  const email = useTypedSelector(selectUserEmail);
  const funderId = useTypedSelector(selectUserId);

  const { amount, currency, walletId, reference } = walletTopUpInfo;

  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletModalStepTwo.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  const queryClient = useQueryClient();
  const { data: wallectAccountsData, isLoading: wallectAccountsDataLoading } =
    useWalletAccountDetails({ targetId: funderId });

  const copyText = (text: number) => {
    setCopied("Copied.");
    navigator.clipboard.writeText(text.toString());
    setTimeout(() => setCopied(""), 3000);
  };
  const config = paystackConfig({
    email,
    amount: Number(amount),
    //@ts-ignore
    currency,
    reference,
  });

  useEffect(() => {
    console.log({ currency });
  }, [currency]);

  const onPaystackClose = () => {
    // console.log("closed");
  };
  //@ts-ignore
  const initializePayment = usePaystackPayment(config);

  const onPaystackSuccess = ({
    reference,
    status,
    message,
  }: {
    reference: string;
    status: string;
    message: string;
  }) => {
    setPaymentRef(reference);
    setPaymentStatus(status);
    setPaymentResponse(message);
    topUpWallet({ reference, status, message });
  };
  const topUpWallet = async ({
    reference,
    status,
    message,
  }: {
    reference: string;
    status: string;
    message: string;
  }) => {
    setLoading(true);
    setError("");
    setSuccess("");
    const reqBody = {
      gatway_response: message,
      status,
      payment_gateway_reference: reference,
      internal_payment_reference: config.reference,
      wallet_id: walletId,
      amount: Number(amount),
      currency,
      funder_id: funderId,
    };

    try {
      await postData(apiEndpoints.FUND_WALLET, reqBody);
      setLoading(false);
      setSuccess("Transaction Successful");
      queryClient.invalidateQueries(["getWalletBalance", { walletId }]);
      queryClient.invalidateQueries([
        "getWalletTransactions",
        { walletId, funderId },
      ]);
      setTimeout(() => {
        closeModal();
      }, 10000);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: WalletModalStepTwo },
      });
    }
  };

  const onSubmit = async () => {
    setError("");
    if (type === PAYMENT_OPTIONS.PAYSTACK) {
      try {
        return initializePayment(onPaystackSuccess, onPaystackClose);
      } catch (error) {
        appInsights.trackException({
          exception: error,
          properties: { fileName: WalletModalStepTwo },
        });
        return setError(errorHandler(error));
      }
    } else if (type === PAYMENT_OPTIONS.BANK_TRANSFER) {
      setDisplayTransferDetails(true);
    } else {
      return setError("Please choose a payment method");
    }
  };

  const onSubmitTransfer = async () => {
    queryClient.invalidateQueries(["getWalletBalance", { walletId }]);
    queryClient.invalidateQueries([
      "getWalletTransactions",
      { walletId, funderId },
    ]);
    setTimeout(() => {
      closeModal();
    }, 1000);
  };

  return (
    <Fragment>
      <div className="modal-header pb-2 mb-4">
        <h5 className="page-subtitle">Payment Method</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Fragment>
        {loading ? (
          <div className="py-5 d-flex justify-content-center align-items-center flex-column">
            <Loader type="lg" variant="blue" />
          </div>
        ) : error ? (
          <div className="py-5 d-flex justify-content-center align-items-center flex-column">
            <h6 className="color-red font-weight-bold text-center">
              {errorHandler(error) ||
                "An error occured while making payment, please try again."}
            </h6>

            <button
              type="button"
              className="btn btn-sm advancly-white-btn  mt-3"
              onClick={() =>
                topUpWallet({
                  reference: paymentRef,
                  status: paymentStatus,
                  message: paymentResponse,
                })
              }
              disabled={loading}
            >
              Retry {loading && <Loader />}
            </button>
          </div>
        ) : success ? (
          <Fragment>
            <div className="d-flex text-center justify-content-center align-items-center flex-column">
              <SuccessIcon />
              <h5 className="page-subtitle my-3">Transaction Successful</h5>
            </div>
          </Fragment>
        ) : displayTransferDetails ? (
          wallectAccountsDataLoading ? (
            <PageLoader />
          ) : (
            <Fragment>
              {copied && <p className="text-success">{copied}</p>}

              {currency === "USD" ? (
                <div className="py-3">
                  <div className="bg-lighter-grey p-3">
                    <div className="mb-4">
                      <div className="form-label mb-2">
                        <small>Account Name</small>
                      </div>
                      <h6 className="page-subtitle">
                        {domDetails.accountName}
                      </h6>
                    </div>

                    <div className="mb-4">
                      <div className="form-label mb-2">
                        <small>Account Number</small>
                      </div>
                      <h6 className="page-subtitle">
                        {domDetails.accountNumber}
                        <span
                          className="font-weight-bold cursor-pointer ml-4"
                          onClick={() => copyText(domDetails.accountNumber)}
                        >
                          <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                        </span>
                      </h6>
                    </div>

                    <div className="mb-4">
                      <div className="form-label mb-2">
                        <small>Bank Name</small>
                      </div>
                      <h6 className="page-subtitle">{domDetails.bankName}</h6>
                    </div>

                    <div className="mb-4">
                      <div className="form-label mb-2">
                        <small>Beneficiary Bank Swift Code</small>
                      </div>
                      <h6 className="page-subtitle">
                        {domDetails.beneficiaryBankSwiftCode}
                      </h6>
                    </div>

                    <div className="mb-4">
                      <div className="form-label mb-2">
                        <small>Corresponding Bank</small>
                      </div>
                      <h6 className="page-subtitle">
                        {domDetails.correspondingBank}
                      </h6>
                    </div>

                    <div className="mb-4">
                      <div className="form-label mb-2">
                        <small>Swift Code</small>
                      </div>
                      <h6 className="page-subtitle">{domDetails.swiftCode}</h6>
                    </div>
                  </div>
                </div>
              ) : (
                wallectAccountsData?.map((wallectAccount: any) => {
                  const { bankName, currency, accountNumber, accountName, id } =
                    wallectAccount;
                  return (
                    <div className="py-3" key={id}>
                      <div className="bg-lighter-grey p-3">
                        <div className="mb-4">
                          <div className="form-label mb-2">
                            <small>Account Name</small>
                          </div>
                          <h6 className="page-subtitle">{accountName}</h6>
                        </div>
                        <div className="mb-4">
                          <div className="form-label mb-2">
                            <small>Currency</small>
                          </div>
                          <h6 className="page-subtitle">{currency}</h6>
                        </div>
                        <div className="mb-4">
                          <div className="form-label mb-2">
                            <small>Account Number</small>
                          </div>
                          <h6 className="page-subtitle">
                            {accountNumber}
                            <span
                              className="font-weight-bold cursor-pointer ml-4"
                              onClick={() => copyText(accountNumber)}
                            >
                              <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                            </span>
                          </h6>
                        </div>
                        <div className="mb-4">
                          <div className="form-label mb-2">
                            <small>Bank Name</small>
                          </div>
                          <h6 className="page-subtitle">{bankName}</h6>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
              <div className="d-flex justify-content-between pt-3">
                <button
                  type="button"
                  className="btn btn-sm advancly-white-btn w-50 mr-2"
                  onClick={() => {
                    setWalletTopUpInfo({
                      ...walletTopUpInfo,
                      currentStep: 2,
                    });
                  }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-sm advancly-btn w-50 ml-2"
                  onClick={onSubmitTransfer}
                >
                  Transfer Done {loading && <Loader />}
                </button>
              </div>
            </Fragment>
          )
        ) : (
          <Fragment>
            {currency === "NGN" && (
              <div
                className={`${styles.modalCard} ${
                  type === PAYMENT_OPTIONS.PAYSTACK && styles.active
                }`}
                onClick={() => setType("Paystack")}
              >
                <div className={styles.radioButton}></div>
                <div>
                  <h6 className="font-weight-bold">
                    Pay with Paystack (Cards, Bank, Transfer)
                  </h6>
                </div>
              </div>
            )}
            {/*<div
              className={`${styles.modalCard} ${
                type === PAYMENT_OPTIONS.BANK_TRANSFER && styles.active
              }`}
              onClick={() => setType("BankTransfer")}
            >
              <div className={styles.radioButton}></div>
              <div>
                <h6 className="font-weight-bold">Transfer to Wallet</h6>
              </div>
            </div>*/}

            <div className="d-flex justify-content-between mt-5 pt-5">
              <button
                type="button"
                className="btn btn-sm advancly-white-btn w-50 mr-2"
                onClick={() => {
                  setWalletTopUpInfo({
                    ...walletTopUpInfo,
                    currentStep: 1,
                  });
                }}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-sm advancly-btn w-50 ml-2"
                disabled={!type || loading}
                onClick={onSubmit}
              >
                Continue {loading && <Loader />}
              </button>
            </div>
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  );
};
