import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { convertToBase64 } from "../../../helpers/base64";
import { TCurrencies, TCurrencyCodes } from "../../../interfaces/currencies";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import {
  currenciesAvailableToFunder,
  LOCAL_CURRENCY_CODE,
  USD_CURRENCY_CODE,
} from "../../../helpers/currencies";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import {
  selectAgreementCheck,
  selectOfflineInvestment,
  selectSelectedInvestment,
} from "../../../redux/selectors/investmentSelector";
import { formatDateAlt } from "../../../helpers/formatDate";
import {
  formatRepaymentType,
  getAdvanclyTranRef,
} from "../../../helpers/generalUtils";
import { selectUser } from "./../../../redux/selectors/userSelector";
import { PAYMENT_OPTIONS } from "./../../../helpers/paymentOptions";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData } from "./../../../apis/apiMethods";
import { errorHandler } from "./../../../helpers/errorHandler";
import Loader from "../../Loader/Loader.component";
import { ReactComponent as SuccessIcon } from "../../../assets/img/coin-icon.svg";
import { resolveDurationName } from "../../../helpers/resolveDuration";
import { add } from "date-fns";
import { appInsights } from "../../AppInsight/AppInsight";

const schema = yup.object().shape({
  paymentDate: yupValidators.genericRequired({
    message: "Choose payment date",
  }),
  transactionReceipt: yupValidators.genericRequired({
    message: "Upload transaction receipt",
  }),
});

interface IOfflineInvestmentStepThree {
  closeModal: () => void;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  success: string;
  setSuccess: Dispatch<SetStateAction<string>>;
}
interface IOfflineInvestmentProps {
  paymentDate: string;
  transactionReceipt: string;
}

const OfflineInvestmentStepThree: React.FC<IOfflineInvestmentStepThree> = ({
  closeModal,
  setError,
  setSuccess,
  success,
}) => {
  const offlineInvestmentInfo = useTypedSelector(selectOfflineInvestment);
  const [loading, setLoading] = useState(false);
  const agreementCheck = useTypedSelector(selectAgreementCheck);

  const selectedInvestment = useTypedSelector(selectSelectedInvestment);
  const { setOfflineInvestmentInfo, setInvestmentStep } = useActions();
  const { funder_id, lcy_wallet_id, usd_wallet_id } =
    useTypedSelector(selectUser);
  const { investment_id, period_type, lcy_product_id, usd_product_id } =
    selectedInvestment;
  const {
    duration,
    repaymentPlan,
    transactionReceiptBase64,
    transactionReceiptName,
    currency,
    amount,
    paymentDate,
  } = offlineInvestmentInfo;
  const fileInputRef = useRef<null | HTMLInputElement>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError: setFieldError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      transactionReceipt: transactionReceiptName,
      paymentDate,
    },
  });

  const onChangeReceipt = async (e: any) => {
    if (!e.target.files[0]) return;
    setError("");
    const fileType = e.target.files[0].type;
    const isValidFile =
      fileType.startsWith("image/png") ||
      fileType.startsWith("image/jpg") ||
      fileType.startsWith("image/jpeg") ||
      fileType.startsWith("application/pdf");

    if (!isValidFile) {
      return setError("Invalid File type.");
    }
    const fileBase64: string = await convertToBase64(e.target.files[0]);
    setValue("transactionReceipt", e.target.files[0].name);

    setOfflineInvestmentInfo({
      ...offlineInvestmentInfo,
      transactionReceiptBase64: fileBase64?.split("base64,")[1],
      transactionReceiptName: e.target.files[0].name,
    });
  };

  const onSubmit = async ({
    paymentDate,
    transactionReceipt,
  }: IOfflineInvestmentProps) => {
    if (!transactionReceiptBase64) {
      return setFieldError("transactionReceipt", {
        type: "required",
        message: "This field is required",
      });
    }
    const advanclyTranRef = getAdvanclyTranRef();
    const effective_date = new Date(paymentDate).toISOString();
    const maturity_date = add(new Date(), {
      days: duration?.days,
    });

    setOfflineInvestmentInfo({
      ...offlineInvestmentInfo,
      paymentDate,
      transactionReceiptName: transactionReceipt,
    });
    setError("");
    if (!repaymentPlan) {
      setError("Select a repayment plan");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (!duration || duration?.days < 1) {
      setError("Select investment duration");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (!agreementCheck) {
      setError("Please agree to terms and conditions ");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    let reqBody = {
      funder_id,
      cba_wallet_account_id:
        currency === USD_CURRENCY_CODE ? usd_wallet_id : lcy_wallet_id,
      investment_product_id: investment_id,
      cba_product_id:
        currency === USD_CURRENCY_CODE ? usd_product_id : lcy_product_id,
      cba_product_interest_id: investment_id,
      principal_amount: Number(amount),
      interest_rate: duration?.interest,
      //@ts-ignore
      interest_amount: Number((amount * duration.interest) / 100),
      effective_date,
      maturity_date,
      funding_channel: PAYMENT_OPTIONS.BANK_TRANSFER,
      currency,
      interest_repayment_type: repaymentPlan,
      payment_gateway_reference: "",
      internal_payment_reference: advanclyTranRef,
      base64_file_string: transactionReceiptBase64,
      filename_with_extension: transactionReceipt,
      tenure: Number(duration?.days),
      investment_product_details_id:
        selectedInvestment?.investment_product_details_id,
    };
    reqBody.cba_wallet_account_id = Number(reqBody.cba_wallet_account_id);
    try {
      await postData(apiEndpoints.MAKE_INVESTMENT, reqBody);
      setLoading(false);
      setSuccess(
        "Your investment will be processed within 24 hours. You will be duly notified once your investment is live."
      );
      setTimeout(() => {
        closeModal();
      }, 10000);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: OfflineInvestmentStepThree },
      });
    }
  };

  const today = new Date(Date.now()).toString();

  return (
    <div>
      {loading ? (
        <div className="d-flex text-center justify-content-center align-items-center flex-column py-5 px-3">
          <Loader type="lg" variant="blue" />
          <div className="mt-3">Processing Request...</div>
        </div>
      ) : success ? (
        <div className="d-flex text-center justify-content-center align-items-center flex-column p-4">
          <SuccessIcon />
          <h5 className="page-subtitle my-3">Investment Logged</h5>
          {success}
        </div>
      ) : (
        <Fragment>
          <div className="modal-header p-0 mb-2">
            <h5 className="page-subtitle mb-3">Manual Investment Log</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="mt-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomSelectDropdown readOnly value={currency}>
                <option value="">Currency</option>
                {currenciesAvailableToFunder({
                  lcy_wallet_id,
                  usd_wallet_id,
                })?.map(
                  ({
                    name,
                    code,
                  }: {
                    name: TCurrencies;
                    code: TCurrencyCodes;
                  }) => {
                    return (
                      <option
                        value={code}
                        key={code}
                        className="text-capitalize"
                      >
                        {name}
                      </option>
                    );
                  }
                )}
              </CustomSelectDropdown>
              <CustomInputField
                type="text"
                readOnly
                label="Loan Tenor"
                placeholder="Loan Tenor"
                defaultValue={`${duration?.days} ${resolveDurationName(
                  period_type
                )}`}
              />
              <CustomInputField
                type="text"
                label="Repayment Plan"
                placeholder="Repayment Plan"
                defaultValue={
                  repaymentPlan && formatRepaymentType(repaymentPlan)
                }
                readOnly
              />
              <CustomInputField
                type="number"
                enableSeparator
                maxLength={20}
                placeholder="e.g. 100,000.00"
                label="Amount Invested"
                readOnly
                defaultValue={amount}
              />
              <CustomInputField
                type="date"
                maxLength={128}
                label="Payment Date"
                errors={errors.paymentDate}
                {...register("paymentDate")}
                max={formatDateAlt(today)}
              />
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder=""
                label="Kindly upload your transaction receipt"
                errors={errors.transactionReceipt}
                hasActionButton={true}
                actionButtonText={
                  transactionReceiptBase64 ? "Replace" : "Choose"
                }
                onClickActionButton={() => {
                  fileInputRef?.current?.click();
                }}
                {...register("transactionReceipt")}
              />
              <input
                type="file"
                className="d-none"
                ref={fileInputRef}
                onChange={onChangeReceipt}
                accept="image/png, image/jpg, image/jpeg, application/pdf"
              />
              <div className="d-flex justify-content-between align-items-center mt-3 flex-wrap">
                <button
                  type="button"
                  className="btn btn-sm advancly-nobg-btn w-50"
                  onClick={() => setInvestmentStep(3)}
                >
                  Previous
                </button>
                <button
                  type="submit"
                  className="btn btn-sm advancly-white-btn w-50"
                  disabled={loading}
                >
                  Log Investment
                </button>
              </div>
            </form>
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default OfflineInvestmentStepThree;
