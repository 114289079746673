import { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import InvestmentMap from "../../components/InvestmentMap/InvestmentMap.component";
import DashboardNoInvestment from "../../components/DashboardNoInvestment/DashboardNoInvestment.component";
import formatMoney from "./../../helpers/formatMoney";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import {
  selectShowBalance,
  selectUser,
  selectUserActivationStatus,
  selectUserId,
  selectUserWallets,
} from "./../../redux/selectors/userSelector";
import useWalletBalance from "./../../hooks/custom-hooks/useWalletBalance";
import Alert from "../../components/Alert/Alert.component";
import { errorHandler } from "./../../helpers/errorHandler";
import Loader from "../../components/Loader/Loader.component";
import { DashboardCards } from "./../../components/DashboardCards/DashboardCards.component";
import { capitalizeFirstLetter } from "./../../helpers/capitalizeFirstLetter";
import useDashboardStats from "../../hooks/custom-hooks/useDashboardStats";
import { appInsights } from "../../components/AppInsight/AppInsight";
import SwitchButton from "../../components/SwitchButton/SwitchButton.component";
import { useActions } from "../../hooks/redux-hooks/useActions";
import { currenciesSelector } from "../../redux/selectors/currenciesSelector";

export default function Dashboard() {
  const history = useHistory();
  const currencyList: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[] = useTypedSelector(currenciesSelector);
  const { lcy_wallet_id, usd_wallet_id } = useTypedSelector(selectUserWallets);
  const [activeTab, setActiveTab] = useState("");
  const { setShowBalance } = useActions();
  const isActivated = useTypedSelector(selectUserActivationStatus);
  const funderId = useTypedSelector(selectUserId);
  const showBalance = useTypedSelector(selectShowBalance);

  useEffect(() => {
    if (currencyList.length > 0) {
      setActiveTab(currencyList[0]?.currencyCode);
    }
  }, [currencyList]);

  const {
    data: wallet,
    error,
    isLoading,
  } = useWalletBalance({
    walletId:
      activeTab === currencyList[0]?.currencyCode
        ? lcy_wallet_id
        : usd_wallet_id,
    funderId,
  });

  const {
    isLoading: dashboardStatsLoading,
    error: dashboardStatsError,
    data,
  } = useDashboardStats();

  const dataToShow =
    activeTab === currencyList[1]?.currencyCode
      ? data?.usd_summary
      : activeTab === currencyList[0]?.currencyCode
      ? data?.lcy_summary
      : null;

  const {
    first_name,
    last_name,
    business_name,
    funder_type_id,
    isBvnValidated,
    country,
  } = useTypedSelector(selectUser);

  useEffect(() => {
    appInsights.trackPageView({ name: "Dashboard.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    if (
      country === "NG" &&
      !isBvnValidated &&
      localStorage.getItem("bvnalert")
    ) {
      history.push(ROUTES.PROFILE_SETTINGS);
    }
  }, []);

  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      <div className="title-align">
        {/* Display Business Name for Business and Full Name for individuals  */}
        <div className="page-title">
          {funder_type_id === 1
            ? `${capitalizeFirstLetter(first_name)} ${capitalizeFirstLetter(
                last_name
              )}`
            : funder_type_id === 2
            ? capitalizeFirstLetter(business_name)
            : null}
        </div>
        <div>
          <Link
            className="btn btn-sm advancly-white-btn mr-2"
            to={ROUTES.WALLET}
          >
            Fund Wallet
          </Link>
          <Link className="btn btn-sm advancly-btn" to={ROUTES.INVESTMENTS}>
            New Investment
          </Link>
        </div>
      </div>

      {/* Dashboard Tabs  */}
      <div className="alignToggleTabItems">
        <div className="subtitle-align">
          <div className="d-flex justify-content-between">
            {currencyList &&
              currencyList?.length > 0 &&
              currencyList.map((item) => (
                <div
                  className={`tabItem ${
                    activeTab === item.currencyCode && "activeTab"
                  }`}
                  onClick={() => setActiveTab(item.currencyCode)}
                  key={item.currencyCode}
                >
                  {item.currencyCode} Investments
                </div>
              ))}
          </div>
          <div className="font-weight-bold d-flex">
            <span className="color-bg-blue">Show Wallet Balances</span>
            <span className="mx-3">
              <SwitchButton
                onChange={(checkedState) => setShowBalance(checkedState)}
                loading={false}
                checkedState={showBalance}
              />
            </span>
            <span className="color-bg-blue">Wallet Balance: </span>
            <span className="ml-2">
              {isLoading ? (
                <Loader />
              ) : error ? null : (
                <Fragment>
                  {showBalance
                    ? formatMoney(wallet?.availableBalance, activeTab)
                    : "*****"}
                </Fragment>
              )}
            </span>
            <span
              onClick={() => history.push(ROUTES.WALLET)}
              className="cursor-pointer d-flex align-items-center font-weight-bold"
            >
              <i className="ri-arrow-right-line ml-2 color-blue"></i>
            </span>
          </div>
        </div>
      </div>

      {/* Investment Summary Cards  */}
      <DashboardCards
        activeTab={activeTab}
        error={dashboardStatsError}
        loading={dashboardStatsLoading}
        dataToShow={dataToShow}
      />

      {/* Dashboard Content  */}
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h5 className="page-subtitle my-4">Portfolio</h5>
        {isActivated && (
          <Link
            className="text-underline color-blue cursor-pointer"
            to={`${ROUTES.PORTFOLIO}?activeTab=${activeTab}`}
          >
            Go to {activeTab} Portfolio
          </Link>
        )}
      </div>
      {/* If user hasn't activated account or if he has no investment  */}
      {!isActivated || Number(dataToShow?.total_active_investment) < 1 ? (
        <DashboardNoInvestment />
      ) : (
        <InvestmentMap />
      )}
    </div>
  );
}
