import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { yupValidators } from "../../../../helpers/yupValidators";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTypedSelector } from "../../../../hooks/redux-hooks/useTypedSelector";
import { useActions } from "../../../../hooks/redux-hooks/useActions";
import CustomInputField from "./../../../../components/CustomHTMLElements/CustomInputField";
import { selectWalletTopUp } from "../../../../redux/selectors/walletTopUpSelector";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { getData, postData } from "../../../../apis/apiMethods";
import {
  addCommasToMoney,
  FunderWalletTopup,
} from "../../../../helpers/generalUtils";
import { selectUserId } from "../../../../redux/selectors/userSelector";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { handleFormatAmount } from "../../../../helpers/formatAmount";

interface IWithdrawModalStepOne {
  closeModal: () => void;
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
}

const schema = yup.object().shape({
  amount: yupValidators.genericRequiredNumber({
    message: "Please enter the amount you want to top up",
    matches: "Please enter a valid amount",
    max: 20,
  }),
});

const WalletModalStepOne: React.FC<IWithdrawModalStepOne> = ({
  closeModal,
  setError,
  setSuccess,
}) => {
  const walletTopUpInfo = useTypedSelector(selectWalletTopUp);
  const { setWalletTopUpInfo } = useActions();
  const [isLoading, setIsLoading] = useState(false);
  const [minTopUpAmount, setMinTopUpAmount] = useState(0);
  const { currency, walletId } = walletTopUpInfo;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError: setCustomError,
    // watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: walletTopUpInfo.amount,
    },
  });
  const { setMinimumTopUpAmounts } = useActions();

  useEffect(() => {
    async function getMinimumTopUpAmount() {
      const minimumTopUpAmounts = await getData(
        apiEndpoints.GET_MINIMUM_TOP_UP_AMOUNTS
      );
      setMinimumTopUpAmounts({
        ...walletTopUpInfo,
        minimumTopUpAmounts: minimumTopUpAmounts.data,
      });
    }
    getMinimumTopUpAmount();
  }, []);

  useEffect(() => {
    console.log({ info: walletTopUpInfo.minimumTopUpAmounts });
    const minimum = walletTopUpInfo.minimumTopUpAmounts.find(
      (amt) => amt.currency_code === walletTopUpInfo.currency
    );
    console.log({ minimum });
    if (minimum && minimum.minimum_wallet_topup_amount) {
      setMinTopUpAmount(minimum.minimum_wallet_topup_amount);
    }
    setCustomError("amount", {
      message: " ",
    });
  }, [walletTopUpInfo.currency]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletModalStepOne.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  const funderId = useTypedSelector(selectUserId);

  // const amount = addCommasToMoney(watch("amount"));
  // console.log(amount);
  const initiatePaystackPayment = async (amount: number) => {
    setIsLoading(true);
    const reqPayload = {
      wallet_id: walletId,
      amount: Number(amount),
      currency,
      funder_id: funderId,
    };

    const reqBody = {
      investment_transaction_type_id: FunderWalletTopup,
      amount: Number(amount),
      request_payload: JSON.stringify(reqPayload),
    };

    try {
      const res = await postData(
        apiEndpoints.INITIATE_PAYSTACK_PAYMENT,
        reqBody
      );
      setWalletTopUpInfo({
        ...walletTopUpInfo,
        amount,
        currentStep: 2,
        reference: res.internal_transaction_reference,
      });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: WalletModalStepOne },
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmit = ({ amount }: { amount: string }) => {
    const amt = handleFormatAmount(String(amount));
    console.log({ amt, minimumTopUpAmount: minTopUpAmount });
    if (amt < minTopUpAmount) {
      setCustomError("amount", {
        message: `Minimum top up amount is ${
          walletTopUpInfo.currency
        }${addCommasToMoney(minTopUpAmount)}`,
      });
    } else {
      setError("");
      setSuccess("");
      initiatePaystackPayment(handleFormatAmount(String(amount)));
    }
  };
  return (
    <div>
      <div className="modal-header p-0 mb-4">
        <h5 className="page-subtitle">Top-Up Amount</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            closeModal();
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInputField
          type="number"
          enableSeparator
          defaultValue={getValues("amount")}
          maxLength={20}
          {...register("amount")}
          placeholder="e.g. 100,000.00"
          label="Enter Amount"
          errors={errors.amount}
        />

        <div className="d-flex justify-content-between mt-5 pt-5">
          <button
            type="button"
            className="btn btn-sm advancly-white-btn w-50 mr-2"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-sm advancly-btn w-50 ml-2">
            {isLoading ? (
              <span className="spinner-border text-white" />
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
export default WalletModalStepOne;
