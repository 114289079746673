import React from "react";
import styles from "./LandingLayout.module.scss";
import LogoWhiteBgc from "../../assets/img/logo-alternate.svg";
import onboardingPicture from "../../assets/img/onboardingImage.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";

interface ILandingLayout {}

const LandingLayout: React.FC<ILandingLayout> = ({ children }) => {
  return (
    <main role="main" className={`bg-white ${styles.landingLayout}`}>
      <div className={styles.left}>
        <Link to={ROUTES.LOGIN}>
          <img
            className={styles.logoWhiteBgc}
            src={LogoWhiteBgc}
            alt="Login Logo"
          />
        </Link>

        <div className={styles.content}>{children}</div>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <img
            className={styles.loginImage}
            src={onboardingPicture}
            alt="login"
          />
        </div>
      </div>
    </main>
  );
};
export default LandingLayout;
