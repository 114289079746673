import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "../../../components/Loader/Loader.component";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { yupValidators } from "../../../helpers/yupValidators";
import CustomInputField from "../../../components/CustomHTMLElements/CustomInputField";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import Alert from "../../../components/Alert/Alert.component";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../helpers/routes";
import countryCodeEmoji from "country-code-emoji";
import useWorldCountries from "../../../hooks/custom-hooks/useWorldCountries";
import CustomSelectDropdown from "../../../components/CustomHTMLElements/CustomSelectDropdown";
import { appInsights } from "../../../components/AppInsight/AppInsight";

type BVNResponse = {
  data: {
    first_name: string | null;
    last_name: string | null;
    dob: string | null;
    formatted_dob: string | null;
    mobile: string | null;
    bvn: string | null;
    gender: string | null;
    residential_address: string | null;
  };
};
const nigeriaSchema = yup.object().shape({
  email: yupValidators.email,
  bvn: yupValidators.genericRequired({ min: 11, max: 11 }),
  phone: yupValidators.phoneNumber,
  country: yupValidators.genericRequired({
    message: "Please select your country",
  }),
});

const internationalSchema = yup.object().shape({
  email: yupValidators.email,
  phone: yupValidators.phoneNumber,
  country: yupValidators.genericRequired({
    message: "Please select your country",
  }),
  nationalID: yupValidators.genericRequired({ min: 8, max: 8 }),
});

const unitedStatesSchema = yup.object().shape({
  email: yupValidators.email,
  phone: yupValidators.phoneNumber,
  country: yupValidators.genericRequired({
    message: "Please select your country",
  }),
});

type FormData = {
  email: string;
  country: string;
  phone: string;
  bvn: string;
  nationalID: string;
};

export const EmailVerification = () => {
  const [loading, setLoading] = useState(false);
  const [verifyingID, setVerifyingID] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [countryField, setCountryField] = useState("");

  const signUpDetails = useTypedSelector((state) => state.signUp);
  const { setSignUp } = useActions();
  useEffect(() => {
    appInsights.trackPageView({
      name: "SignUpEmailVerification.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(
      countryField === "NG"
        ? nigeriaSchema
        : countryField === "US"
        ? unitedStatesSchema
        : internationalSchema
    ),
  });

  const country = watch("country");

  const {
    data: countries,
    isLoading,
    error: countriesError,
  } = useWorldCountries();

  useEffect(() => {
    //signUpDetails.bvnValidated
    setCountryField(country);
    console.log({
      country,
      bvnValidated: signUpDetails.bvnValidated,
      verifyingID,
      isLoading,
      loading,
      countryField,
    });
  }, [country, signUpDetails, verifyingID, isLoading, loading, countryField]);

  const onSubmit = async ({
    email,
    phone,
    country,
    bvn,
    nationalID,
  }: FormData) => {
    setError(null);
    if (!signUpDetails?.phoneNoCountryDialCode) {
      setError("Invalid dial code!");
      return;
    }
    setLoading(true);
    let response;
    if (country !== "US") {
      const idType = bvn ? "BVN" : "NATIONAL_ID";
      response = await resolveID({
        countryCode: country,
        identificationNumber: idType === "BVN" ? bvn : nationalID,
      });
      if (!response) return;
    }
    try {
      const reqBody = { email, phone, bvn, countryCode: country };
      await postData(apiEndpoints.VALIDATE_SIGN_UP_EMAIL, reqBody);
      setSignUp({
        ...signUpDetails,
        first_name: response?.first_name || "",
        last_name: response?.last_name || "",
        dob: response?.dob || "",
        bvnValidated: response?.bvnValidated || false,
        email,
        phone_number: phone,
        emailVerified: true,
        country,
        phoneNoCountryDialCode: signUpDetails?.phoneNoCountryDialCode,
        bvn: response?.bvn || "",
        countryCode: country,
      });
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
      appInsights.trackException({
        exception: new Error(error),
        properties: {
          fileName: "EmailVerification.component.tsx",
          lineNumber: "125",
        },
      });
      setError(errorHandler(error));
    }
  };

  useEffect(() => {
    setValue("bvn", "");
    setValue("nationalID", "");
    setSignUp({
      ...signUpDetails,
      phoneNoCountryDialCode: resolveCountryInfo(country)?.dialCode,
    });
  }, [country]);

  const resolveCountryInfo = (countryCode: string) => {
    if (!countryCode) return;
    return countries?.find(({ iso2 }: { iso2: string }) => {
      return iso2 === countryCode;
    });
  };

  const resolveID = async (reqBody: {
    countryCode: string;
    identificationNumber: string;
  }) => {
    setVerifyingID(true);
    try {
      const {
        data: { dob, first_name, last_name, bvn },
      }: BVNResponse = await postData(`${apiEndpoints.RESOLVE_BVN}`, reqBody);
      setSignUp({
        ...signUpDetails,
        first_name: String(first_name),
        last_name: String(last_name),
        dob: String(dob),
        bvnValidated: true,
        bvn: String(bvn),
      });
      return {
        first_name: String(first_name),
        last_name: String(last_name),
        dob: String(dob),
        bvnValidated: true,
        bvn: String(bvn),
      };
    } catch (error) {
      appInsights.trackException({
        exception: new Error(error),
        properties: {
          fileName: "EmailVerification.component.tsx",
          lineNumber: "168",
        },
      });
      setError(errorHandler(error) || "Something went wrong");
      setSignUp({
        ...signUpDetails,
        bvnValidated: false,
      });
    } finally {
      setVerifyingID(false);
    }
  };

  return (
    <div>
      {error && <Alert message={error} />}
      {countriesError && <Alert message={errorHandler(countriesError)} />}
      <form className="my-5" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="font-weight-bold mb-5 color-dark-blue">Sign Up</h3>
        <div className="row">
          <div className="col-md-12">
            <CustomInputField
              type="email"
              maxLength={256}
              {...register("email")}
              label="Email Address"
              placeholder="Enter your email address"
              errors={errors.email}
              extraLabel="(Personal or Business)"
            />
          </div>
          <div className="col-12">
            <CustomSelectDropdown
              label="Country"
              defaultValue={signUpDetails?.country}
              errors={errors.country}
              {...register("country")}
              extraLabel={isLoading ? "Loading countries" : ""}
            >
              <option value="">Select your country</option>
              {countries?.map(
                ({
                  name,
                  iso2,
                  id,
                }: {
                  name: string;
                  iso2: string;
                  id: number;
                }) => (
                  <option value={iso2} key={id}>
                    {name}
                  </option>
                )
              )}
            </CustomSelectDropdown>
          </div>
          {country ? (
            <div className="col-md-12">
              <CustomInputField
                type="number"
                maxLength={20}
                {...register("phone")}
                label="Contact Number"
                placeholder="07000000000"
                name="phone"
                errors={errors.phone}
              >
                <select
                  onChange={(e) =>
                    setSignUp({
                      ...signUpDetails,
                      phoneNoCountryDialCode: e.target.value,
                    })
                  }
                  value={signUpDetails?.phoneNoCountryDialCode}
                >
                  {countries?.map(
                    ({
                      iso2,
                      id,
                      dialCode,
                    }: {
                      iso2: string;
                      id: number;
                      dialCode: string;
                    }) => (
                      <option value={dialCode} key={id}>
                        {iso2 && countryCodeEmoji(iso2)} {dialCode}
                      </option>
                    )
                  )}
                </select>
              </CustomInputField>
            </div>
          ) : null}
          {!country || country === "US" ? null : country?.toLowerCase() ===
            "ng" ? (
            <div className="col-md-12">
              <CustomInputField
                type="number"
                maxLength={11}
                minLength={11}
                {...register("bvn")}
                label="BVN"
                placeholder="Enter your bvn"
                errors={errors.bvn}
              />
              {verifyingID && (
                <span className="color-blue font-weight-bold">
                  Verifying BVN...
                </span>
              )}
            </div>
          ) : (
            <div className="col-md-12">
              <CustomInputField
                type="number"
                {...register("nationalID")}
                label="National ID"
                placeholder="Enter your national ID number"
                errors={errors.nationalID}
                maxLength={8}
                minLength={8}
              />
              {verifyingID && (
                <span className="color-blue font-weight-bold">
                  Verifying ID...
                </span>
              )}
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
          <div className="d-flex flex-wrap">
            <span className="text-muted pr-2">Already have an account? </span>
            <Link to={ROUTES.LOGIN} className="color-blue">
              Login
            </Link>
          </div>
          <button
            type="submit"
            value="submit"
            className="btn advancly-btn btn-sm"
            disabled={loading || isLoading || !country || verifyingID}
          >
            Next
            {loading && <Loader />}
          </button>
        </div>
        <div className="mt-2">
          <span className="bg-light-grey " style={{ fontSize: "12px" }}>
            By proceeding to the next step, I acknowledge that I have <br />{" "}
            read and agree to the terms and conditions outlined in <br />
            Advancly's
            <a
              href="https://advancly.com/terms-conditions/"
              rel="noreferrer"
              className="pl-1 pr-1 color-blue"
            >
              Terms of Use
            </a>
            and
            <a
              href="https://advancly.com/privacy-policy/"
              rel="noreferrer"
              className="pl-1 color-blue"
            >
              Privacy Policy.
            </a>
          </span>
        </div>
      </form>
    </div>
  );
};
